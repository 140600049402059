import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPhone, faEnvelope, faGlobe, faVideo } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect } from 'react';
import go from "./p.jpg"
import "./animation.css";
import VanillaTilt from 'vanilla-tilt';

export default function Bussines() {
  useEffect(() => {
    VanillaTilt.init(document.querySelector(".contact-info-container"), {
      max: 10,
      speed: 400,
      glare: true,
      "max-glare": 0.2 
    });

    
    return () => {
      const elements = document.querySelectorAll(".contact-info-container");
      elements.forEach(element => {
        VanillaTilt.destroy(element);
      });
    };
  }, []);

  return (<div className='main'>
    <div  className="contact-info-container">
      <div className='textSection'>
<div className='top'>
    <span className='Heading'>
    <img src={go} className='logo' style={{ borderRadius:"30px"}} />
    <div style={{textAlign:"center"}}>
      <h1 className='heading1'>PQIK TECH SOLUTION</h1>
      <h5 className='heading2'>We Create, You Conquer</h5>
      </div>
    </span>

</div>
<div className='middle'>
  <div className='line'></div>
  <div className='image_outsider_outline'>
  <div className='image_outsider_mid'>
  <div className='image_outsider'>
    <img width='120%' style={{marginTop:"10px"}} src='https://pqik.pk/assets/img/logo2.png' />
  </div>
  </div>
  </div>

</div>
<div className='bottom'>

<div className='contact_section'>

<div className='zoom icons'>
<FontAwesomeIcon icon={faVideo}  />
<div className='icon_video'>
 <p><a href='https://us04web.zoom.us/j/5191733324?pwd=JdPamfDf5inWHXVjqh4XR8yBGZ0jjU.1 '>https://us04web.zoom.us/j/5191733324?pwd=JdPamfDf5inWHXVjqh4XR8yBGZ0jjU.1 </a><br/>You can connect with us on Zoom at<br/><b>PKT:</b> 8:00pm-11:00pm<br/><b>BST:</b> 3:pm-6pm</p>
</div> 
</div>

<div className='phone icons'>
<FontAwesomeIcon icon={faPhone}  />
<div className='icon_number'>
  <p>+923218940095<br/>
</p>
</div>
</div>

<div className='envelope icons'>
<FontAwesomeIcon icon={faEnvelope}  />
<div className='icon_mail'>
 <p>zaayanzubair@pqik.pk</p>
</div> 
</div>

<div className='globe icons'>
<FontAwesomeIcon icon={faGlobe}  />
<div className='icon_phone'>
  <a href="https://pqik.pk/">https://pqik.pk</a>
</div>
</div>

<div className='user icons'>
<FontAwesomeIcon icon={faUser}  />
<div className='icon_phone'>
  <p>Zayan Zubair 
</p>
</div>

</div>
</div>
</div>

      </div>
    <div className='right-div'>
    </div>
    
  </div>
  </div>
);
};
