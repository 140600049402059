import './App.css';
import logo from "./img/abstract-circle-logo-icon-free-png.png"
import CardSection from './screen/Card/App';
import Bussines from './screen/bussines/animation';
import bussines from "./screen/bussines/animation"
function App() {
  return (
    <div className='main'>
      {/* <header className='header'>

        <div className='header_left'>
          <div className='left_img'>
            <img className='header_logo' src={logo} />
          </div>
          <div className='left_title'>
            <h2>Pingo</h2>
          </div>
        </div>


        <div className='header_middle'>
          <div className='pages '>
            <p>Home</p>
          </div>
          <div className='pages '>
            <p>Card</p>
          </div>
          <div className='pages '>
            <p>Detail</p>
          </div>
        </div>


        <div className='header_right'>
          <div className='header_right_wrap'>
            <div className='header_logout'>
              <a className='logout_href' href="#"><h3>Logout</h3></a>
            </div>

            <div className='header_profile'>
              <img className='profile_img' src='https://cdn-icons-png.flaticon.com/512/5987/5987424.png' />
            </div>
          </div>

          <div className='popup_logout'>
            <a className='popup_href' href='#'><h3>Logout</h3></a>

          </div>

        </div>











      </header>
      <section className='HeroSection'>
      <div className='hero_text'>
<p className='trending'>
Trending Product In 2024
</p>
<h1 className='hero_title'>Make Your Interior More <br></br> Minimalistic & Modern
</h1>
<p className='hero_description'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque unde itaquelaborum numquam ullam sequi vero, incidunt quisquam eaque veritatis?
</p>

<div className='hero_btn'>Shop now</div>
      </div>
      <div className='hero_img'>
      <div className='hero_poster'>
    <img className='poster_img' src='https://dreamfurniture.netlify.app/static/media/pngegg.86c42571b558f3f683c8.png' />
      </div>
      </div>

      </section>
<CardSection/>
<CardSection/>


 */}


<Bussines/>



    </div>
  );
}

export default App;
